import React, { useEffect, useContext } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Loadable from 'react-loadable';
import { useInView } from 'react-intersection-observer';
import { SettingsContext } from '../contexts';
import { desktopBreakpoint, desktopVW, mobileVW, zIndex } from '../styles/index';
import HomeHero from '../components/home/HomeHero';
import SEO from '../components/shared/SEO';
import HomeOverlappedImages from '../components/home/HomeOverlappedImages';
import { useGlobalComponentsQuery, useGlobalDictionaryQuery, useLocale } from '../hooks/index';

const HomeCollectionSidebarSection = Loadable({
  loader: () => import('../components/home/HomeCollectionSidebarSection'),
  loading() {
    return <div />;
  },
});

const HomeCollectionGridSection = Loadable({
  loader: () => import('../components/home/HomeCollectionGridSection'),
  loading() {
    return <div />;
  },
});

const HomeParallaxSection = Loadable({
  loader: () => import('../components/home/HomeParallaxSection'),
  loading() {
    return <div />;
  },
});

const HomecollectionDisplaySection = Loadable({
  loader: () => import('../components/home/HomecollectionDisplaySection'),
  loading() {
    return <div />;
  },
});

export interface homeProps {
  data: {
    allContentfulProduct: any;
    contentfulPageHome: {
      title: string;
      seoTitle: string;
      seoDescription: string;
      backgroundImgMobile: {
        title: string;
        fluid: string;
      };
      backgroundImgDesktop: {
        title: string;
        fluid: string;
      };
      backgroundVidioDesktop: string;
      backgroundVidioMobile: string;
      vidiosOverlapped: string;
      heroCatchphrase: string;
      detailsColor: boolean;
      heroLink: {
        slug: string;
      };
      imagesOverlapped: {
        imagesOverlapText: {
          json: string;
        };
        imagesOverlapLink: {
          title: string;
          target: {
            slug: string;
          };
        };
        imagesOverlapMedia: {
          fluid: string;
        };
        imagesOverlapMediaDesktop: {
          fluid: string;
        };
      };
      collectionLeftSidebarSection: {
        mediaSection: {
          subtitle: {
            subtitle: string;
          };
          paragraph: {
            paragraph: string;
          };
        };
        linkImage: {
          fluid: string;
        };
        title: string;
        subtitle: string;
        slug: string;
      };
      collectionRightSidebarSection: {
        mediaSection: {
          paragraph: {
            paragraph: string;
          };
        };
        linkImage: {
          fluid: string;
        };
        title: string;
        subtitle: string;
        slug: string;
      };
      collectionGridSection: {
        title: string;
        items: {
          title: string;
          slug: string;
          productTag: string;
          shopifyData: {
            variants: {
              price: string;
              compare_at_price: string;
              sku: string;
            };
          };
          thumbnailImages: {
            fluid: string;
          };
        };
      };
      parallaxSection: {
        topImage: {
          fluid: string;
        };
        midImage: {
          fluid: string;
        };
        bottomImage: {
          fluid: string;
        };
        text: {
          json: string;
        };
      };
      collectionDisplaySection: {
        slug: string;
        heroTopText: string;
        heroBottomText: string;
        linkImage: {
          fluid: string;
        };
      };
      backgroundColorButtonInHeroSection: string;
      backgroundColorButtonInHeroSectionHover: string;
      textColorButtonInHeroSectionHover: string;
      textColorButtonInHeroSection: string;
      borderColorButtonInHeroSection: string;
      borderColorButtonInHeroSectionHover: string;
      backgroundColorButtonInOverlapped: string;
      textColorButtonInOverlapped: string;
      borderColorButtonInOverlapped: string;
      backgroundColorButtonInOverlappedHover: string;
      textColorButtonInOverlappedHover: string;
      borderColorButtonInOverlappedHover: string;
      backgroundHero: string;
      contentOverlapped: string;
      backgroundColorButtonInCollectionSidebar1: string;
      textColorButtonInCollectionSidebar1: string;
      borderColorButtonInCollectionSidebar1: string;
      bgColorButtonInCollectionSidebar1Hover: string;
      textColorButtonInCollectionSidebar1Hover: string;
      borderColorButtonInCollectionSidebar1hover: string;
      backgroundColorButtonInCollectionSidebar2: string;
      textColorButtonInCollectionSidebar2: string;
      borderColorButtonInCollectionSidebar2: string;
      bgColorButtonInCollectionSidebar2Hover: string;
      textColorButtonInCollectionSidebar2Hover: string;
      borderColorButtonInCollectionSidebar2hover: string;
      backgroundColorButtonInHomeCollection: string;
      textColorButtonInHomeCollection: string;
      borderColorButtonInHomeCollection: string;
      backgroundColorButtonInHomeCollectionHover: string;
      textColorButtonInHomeCollectionHover: string;
      borderColorButtonInHomeCollectionHover: string;
    };
  };
}

const Home = ({ data: { contentfulPageHome, allContentfulProduct } }: homeProps) => {
  const {
    seoTitle,
    seoDescription,
    backgroundImgMobile,
    backgroundImgDesktop,
    backgroundVidioDesktop,
    backgroundVidioMobile,
    vidiosOverlapped,
    heroCatchphrase,
    detailsColor,
    heroLink,
    imagesOverlapped,
    collectionLeftSidebarSection,
    collectionGridSection,
    collectionRightSidebarSection,
    parallaxSection,
    collectionDisplaySection,
    backgroundColorButtonInHeroSection,
    backgroundColorButtonInHeroSectionHover,
    textColorButtonInHeroSectionHover,
    textColorButtonInHeroSection,
    borderColorButtonInHeroSection,
    borderColorButtonInHeroSectionHover,
    backgroundColorButtonInOverlapped,
    textColorButtonInOverlapped,
    borderColorButtonInOverlapped,
    backgroundColorButtonInOverlappedHover,
    textColorButtonInOverlappedHover,
    borderColorButtonInOverlappedHover,
    backgroundHero,
    contentOverlapped,
    backgroundColorButtonInCollectionSidebar1,
    textColorButtonInCollectionSidebar1,
    borderColorButtonInCollectionSidebar1,
    bgColorButtonInCollectionSidebar1Hover,
    textColorButtonInCollectionSidebar1Hover,
    borderColorButtonInCollectionSidebar1hover,
    backgroundColorButtonInCollectionSidebar2,
    textColorButtonInCollectionSidebar2,
    borderColorButtonInCollectionSidebar2,
    bgColorButtonInCollectionSidebar2Hover,
    textColorButtonInCollectionSidebar2Hover,
    borderColorButtonInCollectionSidebar2hover,
    backgroundColorButtonInHomeCollection,
    textColorButtonInHomeCollection,
    borderColorButtonInHomeCollection,
    backgroundColorButtonInHomeCollectionHover,
    textColorButtonInHomeCollectionHover,
    borderColorButtonInHomeCollectionHover,
  } = contentfulPageHome;

  const global = useGlobalComponentsQuery();
  const dictionary = useGlobalDictionaryQuery();
  console.log('dictionary', dictionary);
  // ------- LazyLoading business --------------------------------------

  const [pageMid, pageMidInView] = useInView({ threshold: 0 });
  const [trigger, triggerInView] = useInView({ threshold: 0 });
  const locale = useLocale();
  const {
    renderHome,
    setRenderHome,
    productNews,
    setProductnews,
  }: {
    renderHome: any;
    setRenderHome: any;
    productNews: any;
    setProductnews: any;
  } = useContext(SettingsContext);

  useEffect(() => {
    if (renderHome) {
    } else if (pageMidInView || triggerInView) setRenderHome(true);
  }, [pageMidInView, triggerInView]);
  useEffect(() => {
    if (productNews?.length === 0 && allContentfulProduct?.nodes?.length > 0) {
      let filter = allContentfulProduct?.nodes?.filter((item: any) => item.node_locale === locale);
      setProductnews(filter?.splice(0, 9));
    }
  }, [allContentfulProduct]);
  return (
    <>
      <SEO
        title={seoTitle}
        description={seoDescription}
        image={`https:${global?.seoImage.file.url}`}
      />
      <HomeHero
        backgroundImageMobile={backgroundImgMobile}
        backgroundImageDesktop={backgroundImgDesktop}
        heroCatchphrase={heroCatchphrase}
        detailsColor={detailsColor}
        heroLink={heroLink}
        backgroundVidioDesktop={dictionary.homepageHeroVidioDesktop}
        backgroundVidioMobile={dictionary.homepageHeroVidioMobile}
        backgroundColorButtonInHeroSection={backgroundColorButtonInHeroSection}
        backgroundColorButtonInHeroSectionHover={backgroundColorButtonInHeroSectionHover}
        textColorButtonInHeroSectionHover={textColorButtonInHeroSectionHover}
        textColorButtonInHeroSection={textColorButtonInHeroSection}
        borderColorButtonInHeroSection={borderColorButtonInHeroSection}
        borderColorButtonInHeroSectionHover={borderColorButtonInHeroSectionHover}
        backgroundHero={backgroundHero}
      />
      <HomeOverlappedImages
        imagesOverlapped={imagesOverlapped}
        vidiosOverlapped={vidiosOverlapped}
        backgroundColorButtonInOverlapped={backgroundColorButtonInOverlapped}
        textColorButtonInOverlapped={textColorButtonInOverlapped}
        borderColorButtonInOverlapped={borderColorButtonInOverlapped}
        backgroundColorButtonInOverlappedHover={backgroundColorButtonInOverlappedHover}
        textColorButtonInOverlappedHover={textColorButtonInOverlappedHover}
        borderColorButtonInOverlappedHover={borderColorButtonInOverlappedHover}
        contentOverlapped={contentOverlapped}
      />
      <PageMid ref={pageMid}>
        <Trigger ref={trigger} />
        {renderHome && (
          <>
            <HomeCollectionSidebarSection
              collectionMedia={collectionLeftSidebarSection}
              backgroundColorButtonInCollectionSidebar={backgroundColorButtonInCollectionSidebar1}
              textColorButtonInCollectionSidebar={textColorButtonInCollectionSidebar1}
              borderColorButtonInCollectionSidebar={borderColorButtonInCollectionSidebar1}
              bgColorButtonInCollectionSidebarHover={bgColorButtonInCollectionSidebar1Hover}
              textColorButtonInCollectionSidebarHover={textColorButtonInCollectionSidebar1Hover}
              borderColorButtonInCollectionSidebarhover={borderColorButtonInCollectionSidebar1hover}
              leftContent
            />
            <HomeCollectionGridSection collectionGrid={collectionGridSection} />
            <HomeCollectionSidebarSection
              collectionMedia={collectionRightSidebarSection}
              backgroundColorButtonInCollectionSidebar={backgroundColorButtonInCollectionSidebar2}
              textColorButtonInCollectionSidebar={textColorButtonInCollectionSidebar2}
              borderColorButtonInCollectionSidebar={borderColorButtonInCollectionSidebar2}
              bgColorButtonInCollectionSidebarHover={bgColorButtonInCollectionSidebar2Hover}
              textColorButtonInCollectionSidebarHover={textColorButtonInCollectionSidebar2Hover}
              borderColorButtonInCollectionSidebarhover={borderColorButtonInCollectionSidebar2hover}
              leftContent={false}
            />
            <HomeParallaxSection parallaxSection={parallaxSection} />
            <HomecollectionDisplaySection
              collectionDisplaySection={collectionDisplaySection}
              backgroundColorButtonInHomeCollection={backgroundColorButtonInHomeCollection}
              textColorButtonInHomeCollection={textColorButtonInHomeCollection}
              borderColorButtonInHomeCollection={borderColorButtonInHomeCollection}
              backgroundColorButtonInHomeCollectionHover={
                backgroundColorButtonInHomeCollectionHover
              }
              textColorButtonInHomeCollectionHover={textColorButtonInHomeCollectionHover}
              borderColorButtonInHomeCollectionHover={borderColorButtonInHomeCollectionHover}
            />
          </>
        )}
      </PageMid>
    </>
  );
};

const Trigger = styled.div`
  position: absolute;
  top: ${mobileVW(-600)};
  left: 0;
  height: 200px;
  z-index: ${zIndex.basement};

  ${desktopBreakpoint} {
    top: ${desktopVW(-600)};
  }
`;

const PageMid = styled.div`
  min-height: 100vh;
  position: relative;
`;

export default Home;

export const HomePageQuery = graphql`
  query HomePage($id: String!) {
    allContentfulProduct(filter: { productTag: { in: ["New", "Nieuw"] } }) {
      nodes {
        node_locale
        title
        titleToShow
        slug
        artist
        material
        productCategory {
          category
        }
        shopifyData {
          variants {
            compare_at_price
            inventory_quantity
            price
            title
            sku
            id
            product_id
          }
        }
        productTag
        thumbnailImages {
          fluid {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
    contentfulPageHome(id: { eq: $id }) {
      title
      seoTitle
      seoDescription
      backgroundHero
      contentOverlapped
      backgroundImgMobile {
        title
        fluid(maxWidth: 800, quality: 70) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      backgroundImgDesktop {
        title
        fluid(maxWidth: 1800, quality: 50) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      backgroundVidioDesktop
      backgroundVidioMobile
      backgroundColorButtonInHeroSectionHover
      borderColorButtonInHeroSection
      textColorButtonInHeroSectionHover
      textColorButtonInHeroSection
      borderColorButtonInHeroSectionHover
      backgroundColorButtonInOverlapped
      textColorButtonInOverlapped
      borderColorButtonInOverlapped
      backgroundColorButtonInOverlappedHover
      textColorButtonInOverlappedHover
      borderColorButtonInOverlappedHover
      vidiosOverlapped
      backgroundColorButtonInHeroSection
      heroCatchphrase
      detailsColor
      backgroundColorButtonInCollectionSidebar1
      textColorButtonInCollectionSidebar1
      borderColorButtonInCollectionSidebar1
      bgColorButtonInCollectionSidebar1Hover
      textColorButtonInCollectionSidebar1Hover
      borderColorButtonInCollectionSidebar1hover
      backgroundColorButtonInCollectionSidebar2
      textColorButtonInCollectionSidebar2
      borderColorButtonInCollectionSidebar2
      bgColorButtonInCollectionSidebar2Hover
      textColorButtonInCollectionSidebar2Hover
      borderColorButtonInCollectionSidebar2hover
      backgroundColorButtonInHomeCollection
      textColorButtonInHomeCollection
      borderColorButtonInHomeCollection
      backgroundColorButtonInHomeCollectionHover
      textColorButtonInHomeCollectionHover
      borderColorButtonInHomeCollectionHover
      heroLink {
        target {
          ... on ContentfulPageAllProducts {
            slug
          }
          ... on ContentfulPageCollection {
            slug
          }
          ... on ContentfulPageContact {
            slug
          }
        }
      }
      imagesOverlapped {
        imagesOverlapText {
          json
        }
        imagesOverlapLink {
          title
          target {
            ... on ContentfulPageAllProducts {
              slug
            }
            ... on ContentfulPageCollection {
              slug
            }
            ... on ContentfulPagePhilosophy {
              slug
            }
          }
        }
        imagesOverlapMedia {
          fluid(maxWidth: 300, quality: 50) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        imagesOverlapMediaDesktop {
          fluid(maxWidth: 500, quality: 50) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
      collectionLeftSidebarSection {
        mediaSection {
          subtitle {
            subtitle
          }
          paragraph {
            paragraph
          }
        }
        linkImage {
          fluid(maxWidth: 1600, quality: 50) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        title
        subtitle
        slug
      }
      collectionRightSidebarSection {
        mediaSection {
          paragraph {
            paragraph
          }
        }
        linkImage {
          fluid(maxWidth: 1600, quality: 50) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        title
        subtitle
        slug
      }
      collectionGridSection {
        title
        items {
          title
          titleToShow
          slug
          productTag
          shopifyData {
            variants {
              price
              compare_at_price
              sku
              inventory_quantity
              id
              product_id
            }
          }
          thumbnailImages {
            fluid(maxWidth: 810, quality: 70) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
      parallaxSection {
        topImage {
          fluid(maxWidth: 480, quality: 50) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        midImage {
          fluid(maxWidth: 838, quality: 50) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        bottomImage {
          fluid(maxWidth: 1082, quality: 50) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        text {
          json
        }
      }
      collectionDisplaySection {
        slug
        heroTopText
        heroBottomText
        linkImage {
          fluid(maxWidth: 1600, quality: 50) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`;
